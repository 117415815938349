/* eslint-disable react/jsx-no-bind */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import classNames from 'classnames';
import css from './ItemView.APP_TARGET.scss';
import Placeholder from 'Components/Listing/components/Placeholder';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { COMPARE_HASH, ITEM_SOURCE } from 'Constants/items';
import ItemsList from '../ItemsList/ItemsList.APP_TARGET';
import ComparePopupAsync from 'Components/Compare/ComparePopup/ComparePopupAsync';
import withBackButtonHandling from 'HOCs/withBackButtonHandling/withBackButtonHandling';
import { setItem as setSessionStorageItem } from 'Helpers/sessionStorage';
import { getAppliedFiltersLength } from 'Selectors/filtersTanak';
import { isMobile } from 'Helpers/devices';

const itemPostPlaceholderPosition = (config, placeholderOrigin) => {
    const { enabled, home, listing } = config.get('itemPostPlaceholder');

    if (enabled && placeholderOrigin) {
        return placeholderOrigin === 'home'
            ? home.itemsBefore
            : listing.itemsBefore;
    }
    return null;
};

export const ItemViewListing = props => {
    const {
        items,
        expectedItems,
        isFetching,
        visualizationType,
        fourColumns,
        sectionsItems,
        placeholderImagesStyle,
        loadMoreBtn,
        isListingPage,
        onVasTagTap,
        marketConfig,
        isOpened,
        openModal,
        closeModal,
        similarAdsCount,
        categoryTree,
        similarAdsData,
        locationId,
        listingBodyType,
        isShowVideoTag,
        showSponseredTag,
        showDealerTag,
        fromPage
    } = props;

    const renderPlaceholders = () => {
        return Array.from(Array(expectedItems).keys()).map(
            index => <Placeholder key={ `placeholder_${index}` } visualizationType={ visualizationType } placeholderImagesStyle={ placeholderImagesStyle } />);
    };
    const loadingItems = isMobile ? (
        <div className={ css.placeHolderWrapper }>
            {renderPlaceholders()}
        </div>
    ) : (
        renderPlaceholders()
    );

    const sectionsOffset = sectionsItems && sectionsItems.map(({ offset }) => offset);

    const columns = (visualizationType === 'grid' && fourColumns)
        ? css[`${visualizationType}sSideByFour`]
        : css[`${visualizationType}sSide`];

    const isCarCompareEnabled = marketConfig.get('olxAutos', 'isCarCompareEnabled');

    const onItemClick = ({ id }) => {
        let itemIdx = items.map(item => item.id).indexOf(id);

        if (itemIdx === -1) {
            itemIdx = similarAdsData.map(item => item.id).indexOf(id);
        }

        if (itemIdx > -1) {
            setSessionStorageItem('view_item_idx', itemIdx + 1);
        }
    };

    return (
        <React.Fragment>
            <ul className={ classNames(css[`${visualizationType}Items`], columns, { [css.fetchingList]: isFetching }) } data-aut-id="itemsList">
                <ItemsList
                    { ...props }
                    sectionsOffset={ sectionsOffset }
                    loadingItems={ loadingItems }
                    itemPostPlaceholderPosition={ itemPostPlaceholderPosition }
                    isListingPage={ isListingPage }
                    onVasTagTap={ onVasTagTap }
                    openComparePopup={ openModal }
                    similarAdsCount={ similarAdsCount }
                    categoryTree={ categoryTree }
                    onItemClick={ onItemClick }
                    locationId={ locationId }
                    listingBodyType={ listingBodyType }
                    isShowVideoTag={ isShowVideoTag }
                    showSponseredTag={ showSponseredTag }
                    showDealerTag={ showDealerTag }
                    fromPage={ fromPage }
                />
                <li className={ css.loadMoreBtnContainer }>{loadMoreBtn}</li>
            </ul>

            {isCarCompareEnabled && isOpened
                && <ComparePopupAsync
                    isOpened={ isOpened }
                    closeComparePopup={ closeModal }
                />}
        </React.Fragment>
    );
};

export const mapStateToProps = state => ({
    appliedFiltersLength: getAppliedFiltersLength(state)
});

ItemViewListing.propTypes = {
    items: PropTypes.array,
    isFetching: PropTypes.bool,
    fourColumns: PropTypes.bool,
    isShowVideoTag: PropTypes.bool,
    expectedItems: PropTypes.number,
    visualizationType: PropTypes.oneOf(['grid', 'big', 'list']),
    sectionsItems: PropTypes.arrayOf(
        PropTypes.shape({
            offset: PropTypes.number,
            location: PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                type: PropTypes.string
            })
        })
    ),
    placeholderImagesStyle: PropTypes.string,
    marketConfig: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    loadMoreBtn: PropTypes.node,
    isListingPage: PropTypes.bool,
    onVasTagTap: PropTypes.func,
    isOpened: PropTypes.bool,
    closeModal: PropTypes.func.isRequired,
    openModal: PropTypes.func.isRequired,
    similarAdsData: PropTypes.array,
    similarAdsCount: PropTypes.number,
    categoryTree: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    locationId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    listingBodyType: PropTypes.string,
    showSponseredTag: PropTypes.bool,
    showDealerTag: PropTypes.bool,
    fromPage: PropTypes.string
};

ItemViewListing.defaultProps = {
    items: [],
    expectedItems: 20,
    fourColumns: false,
    visualizationType: 'grid',
    itemPostPlaceholderOrigin: '',
    listingType: 'items',
    onItemClick: () => { },
    getListingDescription: () => { },
    filters: {},
    placeholderImagesStyle: '',
    isAdSuggested: false,
    source: ITEM_SOURCE.OTHER,
    loadMoreBtn: null,
    showInspectionTag: false,
    showBP: true,
    locationId: '',
    listingBodyType: '',
    showSponseredTag: false,
    showDealerTag: false,
    fromPage: ''
};

export default compose(
    connect(mapStateToProps),
    withBackButtonHandling(COMPARE_HASH),
    withConfig('marketConfig'),
    React.memo
)(ItemViewListing);
