import React, { useCallback, useEffect } from 'react';
import { compose } from 'redux';
import withRouter from 'HOCs/withRouter';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import cx from 'classnames';
import { LayoutHelper } from 'olx-autos-landing-page';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import UserDetailsFormAsync from 'Components/UserDetailsForm/UserDetailsFormAsync';

import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import { EXPONEA_USER_FLOW, EXPONEA_EVENT_TYPES } from 'Constants/exponeaLeads';
import { fileNames } from 'Constants/imageMarketAssets';
import { SVG_TYPE } from 'Constants/images';
import { FORM_ATTRIBUTE_COMPLETE, LEAD_FORM_SUBMIT, ADPV_EVENTS } from 'Constants/tracking';
import { LEAD_GEN_SOURCES } from 'Constants/items';

import css from './ExchangeCard.scss';
import TradeInFormAsync from 'Components/CrossSell/TradeInForm/TradeInFormAsync';

export const ExchangeCard = ({
    location: {
        pathname,
        query,
        hash
    },
    router: { push: routerPush, goBack: routerGoBack },
    config: { get: configGet },
    intl,
    visualizationType,
    renderSmallExchangeCard,
    track
}) => {
    const overlayHash = configGet('leads', 'exchangeOverlayHash');
    const hideVasTagListing = configGet('hideVasTagListing');

    const checkIfIsInView = useCallback(isInView => {
        if (isInView) {
            track(ADPV_EVENTS.VIEW_LEAD_IMPRESSION, { message_displayed: 'listing_placeholder_car_exch', select_from: 'listings', field_name: LEAD_GEN_SOURCES.EXCHANGE_CAR });
        }
    }, [track]);
    const onClick = useCallback(() => {
        track('exchange_button_tap', {
            select_from: 'listings'
        });
        routerPush({
            pathname,
            query,
            hash: overlayHash
        });
    }, [overlayHash, pathname, query, routerPush, track]);

    const onFieldBlur = useCallback(evt => {
        track(FORM_ATTRIBUTE_COMPLETE, {
            select_from: 'listings',
            field_name: evt.target.name,
            chosen_option: null
        });
    }, [track]);

    const onLeadSubmit = useCallback(({ uid: user_id }) => {
        track(LEAD_FORM_SUBMIT, {
            select_from: 'listings',
            flow_type: LEAD_GEN_SOURCES.EXCHANGE_CAR,
            user_id,
            ...LayoutHelper.getAcquisitionChannelTrackingValues()
        });
    }, [track]);
    const isLeadGenFormOpen = hash === overlayHash;
    const isTradeInFFEnabled = configGet('enableTradeIn');
    const imagePath = `/${configGet('siteCode')}${isTradeInFFEnabled ? fileNames.LISTING_EXCHANGE_TRADE_IN_WIDGET : fileNames.LISTING_EXCHANGE_WIDGET}`;

    useEffect(() => {
        if (isLeadGenFormOpen) {
            track(ADPV_EVENTS.LEAD_FORM_OPEN, { chosen_option: LEAD_GEN_SOURCES.EXCHANGE_CAR, select_from: 'listings' });
        }
    }, [isLeadGenFormOpen, track]);

    return (
        <>
            <li className={ cx(css.itemPostPlaceholder, css[visualizationType], css.exchangeCard, { [css.hideVasTag]: hideVasTagListing }, { [css.smallExchangeCard]: renderSmallExchangeCard }) } onClick={ onClick } data-aut-id="trade-in-cta">
                <ReactObserver onChange={ checkIfIsInView } triggerOnce className={ css.exchangeWidgetAsset } >
                    <ImageWrapper
                        fileName={ imagePath }
                        alt=" exchange your car"
                        types={ SVG_TYPE }
                        className={ css.exchangeCardImg }
                        height={ 233 }
                        width={ 395 }
                    />
                </ReactObserver>
            </li>
            { isLeadGenFormOpen
                        && (isTradeInFFEnabled
                            ? (
                                <TradeInFormAsync selectFrom={ 'Listing' } />
                            )
                            : (
                                <UserDetailsFormAsync
                                    onClose={ routerGoBack }
                                    userFlow={ EXPONEA_USER_FLOW.B2C }
                                    eventType={ EXPONEA_EVENT_TYPES.SWAP_SUBMIT }
                                    eventOrigin="listing"
                                    thankYouTitle={ intl.formatMessage({ id: 'thankYouVeryMuch' }) }
                                    thankYouSubtitle={ intl.formatMessage({ id: 'interestedThankYouSubtitle' }) }
                                    onFieldBlur={ onFieldBlur }
                                    onSubmitComplete={ onLeadSubmit }
                                    track={ track }
                                    hp_tracking_event="listings"
                                />
                            )
                        )
            }
        </>
    );
};

ExchangeCard.propTypes = {
    visualizationType: PropTypes.string,
    config: PropTypes.shape({
        get: PropTypes.func
    }).isRequired,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string,
        query: PropTypes.object,
        hash: PropTypes.string
    }).isRequired,
    router: PropTypes.shape({
        push: PropTypes.func,
        goBack: PropTypes.func
    }).isRequired,
    track: PropTypes.func.isRequired,
    renderSmallExchangeCard: PropTypes.bool
};

ExchangeCard.defaultProps = {
    visualizationType: 'grid'
};

export default compose(
    withRouter,
    withConfig,
    withTrack,
    injectIntl
)(ExchangeCard);
