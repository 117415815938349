import React, { useCallback } from 'react';
import { compose } from 'redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactObserver from 'Components/ReactObserver/ReactObserver';
import ImageWrapper from 'Components/ImageWrapper/ImageWrapper';
import { selectBrandPromiseData, selectBrandPromiseItems, selectUspVasData } from 'Selectors/config';
import { X, XX } from 'Constants/images';
import { WIDGET_SHOWN, VAS_TAGS_EVENTS } from 'Constants/tracking';
import { isMobile } from 'Helpers/devices';

import { withConfig } from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';

import css from './BrandPromise.APP_TARGET.scss';
import { setVasItem } from 'Actions/vasSheet';
import IconWrapper from 'Components/IconWrapper/IconWrapper';
import BrandPromiseItem from './BrandPromiseItem/BrandPromiseItem';
import { noop } from 'Helpers/function';
import { getVasConfigValues } from 'Selectors/config';
import { GEN_CONFIG, SITE_CODE } from 'Constants/genConfig';
import {
    ICONS,
    ICON_COLORS
} from 'Constants/items';

const RenderPillCountMob = 3;
const RenderPillCountDesk = 6;
const TICK_TRANSPARENT_ICON_SIZE = 17;
const TICK_TRANSPARENT_ICON_VIEW_BOX = '0 0 17 17';

export const BrandPromise = ({
    title,
    items,
    isListingPage,
    track,
    subTitle,
    buttonTitle,
    mobileSubTitle,
    marketConfig,
    isNewUspVasDesign,
    onVasTagTap,
    isAdPage,
    isTr,
    vasClickedTrackingPayload
}) => {
    const RenderPillCount = isMobile ? RenderPillCountMob : RenderPillCountDesk;

    const onView = useCallback(isInView => {
        if (isInView && !isAdPage) {
            track(WIDGET_SHOWN, { message_displayed: 'brand_promise' });
        }
    }, [track, isAdPage]);
    const brandLogo = marketConfig.get('brandLogo');

    if (!title || !items || !items.length) {
        return null;
    }

    const handleTap = e => {
        e?.stopPropagation();
        e?.preventDefault();

        track(VAS_TAGS_EVENTS.VAS_TAG_CLICKED, vasClickedTrackingPayload);

        setVasItem({
            isFrmListing: !isAdPage
        });
        onVasTagTap();
    };

    const renderListingHeader = () => {
        if (!isListingPage) {
            return null;
        }

        const filteredTitle = isMobile ? mobileSubTitle : subTitle;

        return (<div className={ classNames({
            [css.desktopHeader]: !isMobile,
            [css.newUspVasDesignHeader]: isMobile
        }) }>
            <div className={ css.heading }>{title}</div>
            <div className={ css.subHeading }>
                <IconWrapper
                    icon={ ICONS.TICK_TRANSPARENT }
                    name="Icon"
                    size={ TICK_TRANSPARENT_ICON_SIZE }
                    viewBox={ TICK_TRANSPARENT_ICON_VIEW_BOX }
                    color={ ICON_COLORS.WHITE }
                />
                {filteredTitle}
            </div>
        </div>);
    };

    const renderVASPill = (title, imageURI, idx) => (
        <React.Fragment key={ idx }>
            <BrandPromiseItem
                id={ `${title}-${idx}` }
                label={ title }
                imageURI={ imageURI }
                isListingPage={ isListingPage }
                isAdPage={ isAdPage }
            />
        </React.Fragment>
    );

    const getVASPill = ({ title, imageURI }, idx) => {
        if (isAdPage) {
            const shouldRender = RenderPillCount > idx;

            return shouldRender && renderVASPill(title, imageURI, idx);
        }
        return renderVASPill(title, imageURI, idx);
    };

    const renderVASPills = () => {
        return items.map(getVASPill);
    };

    const renderNewBrandPromiseBanner = () => (
        <>
            {renderListingHeader()}
            <div className={ css.bpItemsWrapper }>
                {renderVASPills()}
                <div className={ css.knowMoreBtn }>{buttonTitle}</div>
            </div>
        </>
    );

    const renderOldBrandPromiseBanner = () => (
        <>
            <ImageWrapper
                fileName={ brandLogo }
                className={ css.olxautosLogo }
                fileSize={ isListingPage ? XX : X }
            />
            <div className={ css.heading }>{title}</div>
            <div className={ css.subHeading }>{subTitle}</div>
            <ul className={ css.bp }>
                {items.map(({ title, subtitle, imageURI }, idx) => (
                    <li key={ `bp-${idx}` } className={ css.bpItem } data-aut-id="brandBannerListItem">
                        <div className={ css.imgWrapper } data-aut-id="brandBannerListImage">
                            <ImageWrapper
                                fileName={ imageURI?.replace(/v1/, 'v2') }
                                className={ css.bpImage }
                                fileSize={ XX }
                            />
                        </div>
                        <div className={ css.bpContent }>
                            <div className={ css.bpTitle } data-aut-id="brandBannerListItemTitle">{title}</div>
                            <div className={ css.bpSubtitle }>{subtitle}</div>
                        </div>
                    </li>
                ))}
            </ul>
        </>);

    const TRHeaderClass = isMobile ? css.newUspVasDesignTRMobHeader : css.newUspVasDesignTRDesktopHeader;

    return (
        <ReactObserver
            onChange={ onView }
            className={ classNames(css.brandPromise, {
                [css.listingBrandPromise]: isListingPage,
                [css.newUspVasDesign]: isNewUspVasDesign,
                [css.adpPageUspVasDesign]: isAdPage,
                [TRHeaderClass]: isNewUspVasDesign && isTr
            }) }
            onClick={ isNewUspVasDesign ? handleTap : noop }
            data-aut-id="brandPromiseBanner"
            triggerOnce
        >
            {isNewUspVasDesign ? renderNewBrandPromiseBanner() : renderOldBrandPromiseBanner()}
        </ReactObserver>
    );
};

BrandPromise.propTypes = {
    title: PropTypes.string,
    subTitle: PropTypes.string,
    mobileSubTitle: PropTypes.string,
    buttonTitle: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            subtitle: PropTypes.string,
            imageURI: PropTypes.string
        })
    ),
    isListingPage: PropTypes.bool,
    isNewUspVasDesign: PropTypes.bool,
    track: PropTypes.func.isRequired,
    onVasTagTap: PropTypes.func,
    marketConfig: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    isAdPage: PropTypes.bool,
    isTr: PropTypes.bool,
    vasClickedTrackingPayload: PropTypes.object
};

BrandPromise.defaultProps = {
    onVasTagTap: () => {},
    isAdPage: false,
    isTr: false
};

export const mapStateToProps = (state, ownProps) => {
    const { marketConfig, user, isNewUspVasDesign, isAdPage, item } = ownProps;
    const brandPromiseData = isNewUspVasDesign ? selectUspVasData(state) : selectBrandPromiseData(state, { marketConfig, user });
    const isTr = marketConfig?.get(GEN_CONFIG.siteCode) === SITE_CODE.OLX_TR;
    const userVasTags = user?.additional_services || [];
    let itemVasTags = item?.vasTags || item?.value_added_services || [];

    if (isAdPage) {
        itemVasTags = item?.value_added_services || item?.vasTags || [];
    }

    const vasTags = itemVasTags || userVasTags || [];
    let items = [];

    if (isNewUspVasDesign && !isAdPage) {
        items = brandPromiseData?.items?.slice(0, brandPromiseData?.max_brandPromise);
    }
    else if (isNewUspVasDesign && isAdPage) {
        items = getVasConfigValues(state, vasTags, true, isAdPage);
    }
    else {
        items = selectBrandPromiseItems(state, { marketConfig, user });
    }

    return {
        title: brandPromiseData?.header_Text,
        subTitle: brandPromiseData?.header_subtitle,
        mobileSubTitle: brandPromiseData?.banner_subtitle,
        buttonTitle: brandPromiseData?.button_title,
        items,
        isTr
    };
};
export default compose(
    withConfig('marketConfig'),
    connect(mapStateToProps, null),
    withTrack,
    React.memo
)(BrandPromise);
